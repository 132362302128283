import { graphql } from 'gatsby'
import get from 'lodash/get'
import React, { Component } from 'react'

import { withStyles } from '@material-ui/core/styles'

import Layout from '../components/layout'
import Markdowm from '../components/organisms/markdown'
import BlogCategory from '../models/blogCategory/blogCategory'
import { ArticleGoogleStructuredConfig } from '../models/seoConfig/ArticleGoogleStructure'
import SEOConfig from '../models/seoConfig/seoConfig'
import PageType from '../models/siteConfig/pageType'
import SiteConfig from '../models/siteConfig/siteConfig'

const styles = {
  update: {
    fontSize: 14,
  }
}

type PrivacyPolicy = {
  content: {
    childMarkdownRemark: {
      html: string
    }
  },
  createdAt: string,
  updatedAt: string
}


class PrivacyPolicyPage extends Component {
  render() {
    const blogCategories: BlogCategory[] = get(this, 'props.data.allContentfulBlogPostCategory.nodes')
    const privacyPolicy: PrivacyPolicy = get(this, 'props.data.contentfulPrivacyPolicy')
    const siteConfig: SiteConfig = get(this, 'props.data.site.siteMetadata.siteConfig')
    const classes = get(this, 'props.classes')
    const seoConfig: SEOConfig = new SEOConfig(PageType.PrivacyPolicy, null, null, null, siteConfig, siteConfig.baseURL + '/' + siteConfig.siteImageSrc, null)
    const articleStructured: ArticleGoogleStructuredConfig = {}
    return (
      <Layout seoConfig={seoConfig} blogCategories={blogCategories} articleStructured={articleStructured}>
        <article className='markdown-body'>
          <h1>プライバシーポリシー</h1>
          <div className={classes.update}>{privacyPolicy.updatedAt}</div>
          <Markdowm html={privacyPolicy.content.childMarkdownRemark.html} />
        </article>
      </Layout>
    )
  }
}

export default withStyles(styles)(PrivacyPolicyPage)

export const blogPostListQuery = graphql`
query PrivacyPolicyQuery {
  site {
    siteMetadata {
      siteConfig {
        title
        description
        siteImageSrc
        baseURL
        author {
          name
          nameCall
          bio
          medias {
            name
            link
            viewBox
            paths {
              d
              fill
            }
          }
        }
      }
    }
  }
  allContentfulBlogPostCategory {
    totalCount
    nodes {
      name
      slug
    }
  }
  contentfulPrivacyPolicy {
    id
    content {
      childMarkdownRemark {
        html
      }
    } 
    createdAt(formatString: "記事投稿日 YYYY年 MM月 DD日")
    updatedAt(formatString: "最終更新日 YYYY年 MM月 DD日")
  }
}
`
